import { Injectable, inject } from '@angular/core';
import { CanMatchFn, ResolveFn } from '@angular/router';
import { HttpParams } from "@angular/common/http";
import { environment } from 'environments/environment';
import { Access, GamePageData, GameCards, DeckCards, GamePageDataWithCards } from "app/models/game-data";
import { Observable, of, map, shareReplay, MonoTypeOperatorFunction, pluck, Subscription, Subscriber, delay, retry, timer } from 'rxjs';

import { HttpService } from './http.service';

export interface DeckAPIResponse {
  title: string;
  access: Access;
  subheading: string;
  how_to_play: string;
  description: string;
  meta: {slug: string};
  title_image: {download_url: string};
  children?: boolean;
}

export interface DeckWithCardsAPIResponse extends DeckAPIResponse {
  cards: DeckCards;
}

export interface ShortDeckAPIResponse {
  title: string;
  access: Access;
  meta: {slug: string, show_in_menus: boolean, detail_url: string};
}

interface DeckListingAPIResponse {
  meta: {total_count: number};
  items: ShortDeckAPIResponse[];
}


export interface BaseMenuItem {
  label: string;
  url: string;
  is_new?: boolean;
  classname?: string;
  children?: undefined;
}

export interface SubMenu {
  label: string;
  children?: BaseMenuItem[];
  url: undefined;
}

export type MenuItem = BaseMenuItem | SubMenu;
export interface DeckButton {
  text: string;
  link: string;
}

export interface HomePageData {
  main_menu: MenuItem[];
  deck_count: number;
  deck_buttons: BaseMenuItem[];
  random_suggest: BaseMenuItem[];
}

const sentinelHomePageData: HomePageData = {
  main_menu: [
    {label: "Главная", url: "/"},
    {label: "Бесплатная колода", url: "/free/", classname: "promo"}
  ],
  deck_count: 1,
  deck_buttons: [
    {label: "вашей жизни", url: "/free/"}
  ],
  random_suggest: [
    {label: "Давай узнаем друг друга", url: "/free/"}
  ]
}

/** Shares a subscription to source observable, until a timer elapses, at which point
 * new subscriptions to the returned observable will create a new shared subscription.
 */
function cacheReplay<T>(cacheInterval: number): MonoTypeOperatorFunction<T> {
  return (source) => {
    const factory = () => source.pipe(
      shareReplay({refCount: false, windowTime: cacheInterval, bufferSize: 1})
    );
    let connection: Observable<T> | undefined = undefined;

    function forward(sub: Subscriber<T>) {
      let innerSub: Subscription | undefined = undefined;
      innerSub = connection!.subscribe({
        next(v) {
          sub.next(v);
          sub.complete();
        },
        complete() {
          if (!sub.closed) {
            connection = factory();
            forward(sub);
          }
        },
        error(err) {
          sub.error(err);
          connection = undefined;
        },
      });
      sub.add(innerSub);
    }

    return new Observable((subscriber: Subscriber<T>) => {
      connection ??= factory();
      forward(subscriber);
    })
  }
}

function raceWithSentinel<T>(sentinel: T, delayTime: number): MonoTypeOperatorFunction<T> {
  const sentinelDelayed = of(sentinel).pipe(delay(delayTime));
  return (source) => {
    return new Observable((subscriber) => {
      let gotValue = false;
      let tappedSub: Subscription | undefined = undefined;
      let sentinelSub: Subscription | undefined = undefined;
      function beDone(subscribeDirectly: boolean) {
        if (subscribeDirectly && !gotValue) source.subscribe(subscriber);
        if (tappedSub) { tappedSub.unsubscribe(); tappedSub = undefined; }
        if (sentinelSub) { sentinelSub.unsubscribe(); sentinelSub = undefined; }
        gotValue = true;
      }
      tappedSub = source.subscribe({
        next(v) { beDone(true); subscriber.next(v); },
        complete() { beDone(false); subscriber.complete(); },
        error(e) { beDone(false); subscriber.error(e); }
      })
      // if immediately got a value, unsub
      if (gotValue) { tappedSub.unsubscribe(); return; }
      else {
        sentinelSub = sentinelDelayed.subscribe({
          next(v) {
            if (!gotValue) subscriber.next(v);
            beDone(true);
          },
          complete() { beDone(true); }
        })
      }
    })
  }
}


@Injectable()
export class CMSService {
  deckListing$: Observable<ShortDeckAPIResponse[]>;
  homePageData$: Observable<HomePageData>;

  constructor(private http: HttpService) {
    this.deckListing$ = http.requestJson<DeckListingAPIResponse>(
      'get', CMSService.decksURL.href
    ).pipe(
      pluck('items'),
      retry({delay: (e, cnt) => timer(Math.max(cnt, 10) * 500)}),
      cacheReplay(5 * 60 * 1000)
    );
    this.homePageData$ = http.requestJson<HomePageData>(
      'get', CMSService.homeURL.href
    ).pipe(
      retry({delay: (e, cnt) => timer(Math.max(cnt, 10) * 700)}),
      cacheReplay(5 * 60 * 1000),
      raceWithSentinel(sentinelHomePageData, 2000)
    );
  }

  static decksURL = new URL('api/decks/', environment.backendURL);
  static decksFindURL = new URL('find/', CMSService.decksURL);
  static deckWithCardsFindURL = new URL('/api/deck_cards/find/', environment.backendURL);
  static previewURL = new URL('api/preview/', environment.backendURL);
  static homeURL = new URL('api/home/', environment.backendURL);

  findDeckBySlug(slug: string): Observable<DeckAPIResponse> {
    return this.http.requestJson<DeckAPIResponse>(
      'get', CMSService.decksFindURL.href, null,
      {params: new HttpParams({fromObject: {html_path: '/' + slug}})}
    );
  }

  findDeckCardsBySlug(slug: string, token: string): Observable<DeckWithCardsAPIResponse> {
    return this.http.requestJson<DeckWithCardsAPIResponse>(
      'get', CMSService.deckWithCardsFindURL.href, null,
      {params: new HttpParams({fromObject: {html_path: '/' + slug, code: token}})}
    )
  }

  getDeckListCached() { return this.deckListing$; }

  homePageData() { return this.homePageData$; }

  getCachedBySlug(slug: string): Observable<ShortDeckAPIResponse | null> {
    return this.getDeckListCached().pipe(
      map((decklist) => decklist.find((deck) => deck.meta.slug === slug) || null)
    )
  }

  getPreview(content_type: string, token: string): Observable<DeckAPIResponse> {
    return this.http.requestJson<DeckAPIResponse>(
      'get', CMSService.previewURL.href, null,
      { params: new HttpParams({ fromObject: { content_type, token } }) }
    );
  }
}


export const hasDeckWithSlug: CanMatchFn = function(route, segments) {
  const slug = segments[0].path;
  const cms = inject(CMSService);
  return cms.getCachedBySlug(slug).pipe(map((deck) => deck !== null));
}

export const deckPageData: ResolveFn<GamePageData> = function(route, state) {
  const cms = inject(CMSService);
  const contenttype = route.queryParams['content_type'];
  const token = route.queryParams['token'];
  let apiData$;
  if (contenttype && token) {
    apiData$ = cms.getPreview(contenttype, token);
  } else {
    apiData$ = cms.findDeckBySlug(route.paramMap.get('slug')!);
  }
  return apiData$.pipe(
    map((deck) => ({
      ...deck,
      meta: undefined,
      slug: deck.meta.slug,
      title_image: new URL(deck.title_image.download_url, environment.backendURL).href,
    }))
  )
}

export const deckPageWithCardsData: ResolveFn<GamePageDataWithCards> = function(route, state) {
  const cms = inject(CMSService);
  const apiData$ = cms.findDeckCardsBySlug(route.paramMap.get('slug')!, route.paramMap.get('url')!);
  return apiData$.pipe(
    map((deck) => ({
      ...deck,
      meta: undefined,
      slug: deck.meta.slug,
      title_image: new URL(deck.title_image.download_url, environment.backendURL).href,
    }))
  )
}

export const homePageData: ResolveFn<HomePageData> = function (route, state) {
  return inject(CMSService).homePageData();
};

